<template>
  <div class="tab-content">
    
    <div class="tab-content">
      <div class="align-start">
        <div class="upload-image mt-4">
          <div class="upload-image__item">
            <div class="upload-image__top">
              <div class="cat-icon-container">
                <img
                  class="image-container-img"
                  :src="inputData.thumbnail_src ? inputData.thumbnail_src : '/img/category-icons/thumbnail-default.svg'"
                  alt="img"
                  style="max-height:108px"
                />
                <div
                  class="img-remove"
                  v-show="inputData.thumbnail_src != '/img/category-icons/thumbnail-default.svg'"
                  @click="removeImage()"
                >
                  X Remove
                </div>
              </div>
            </div>
            <div class="upload-image__bottom">
              <input
                type="file"
                :name="inputData.thumbnail"
                id="inputIcon"
                class="hidden-input"
                @change="changeThumbnail()"
              />
              <label for="inputIcon" class="file-upload">
                <fa-icon icon="upload" class="my-icon" />
                Ubah Thumbnail
              </label>
            </div>
          </div>
        </div>
        <div class="ml-0 ml-md-4 flex-1 sm-w-100">
          <div class="space-between align-start mt-4">
            <div class="align-start">
              <div class="mr-3 mb-4">
                <label>
                  Jenjang
                </label>
                <b-form-select
                  v-model="inputData.tryout_level_id"
                  :options="levelOption"
                  disabled
                ></b-form-select>
              </div>
            </div>
          </div>
          <label>
            Nama Tryout
          </label>
          <b-form-input v-model="inputData.name"></b-form-input>
          <label>
            Price
          </label>
          <b-form-input type="number" v-model="inputData.price"></b-form-input>

          <label class="mt-4">
            Deskripsi
          </label>
          <b-textarea v-model="inputData.description" rows="3"></b-textarea>
          <my-button
            type="blue"
            disabled
            v-if="showLoading"
            rounded
            class="mt-2"
          >
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </my-button>
          <my-button type="blue" class="mt-2" rounded v-else  @click="save">
            <fa-icon class="mr-1" icon="save"> </fa-icon>
            Simpan
          </my-button>
        </div>
      </div>
    </div>
    <label>Ubah Daftar Paket Soal</label>
    <br />
    <br />
    <div class="space-between align-center">
      <div class="align-center flex-1">
      </div>

      <button
        type="button"
        class="btn mulai btn-secondary font-bold"
        @click="pilihSoalBaru"
      >
        <fa-icon icon="edit" class="my-icon" />&nbsp; CARI & TAMBAH PAKET SOAL
      </button>
    </div>
    <br />
    <br />
    <!-- Main table element -->
    <table class="table table-striped">
      <thead class="thead-dark">
        <tr>
          <th scope="col" v-for="field in fields" :key="field.label">
            {{ field.label }}
          </th>
        </tr>
      </thead>
      <draggable v-model="items" tag="tbody" @change="log">
        <tr
          v-for="(item,i) in items"
          :key="item.id"
          style="cursor: all-scroll;"
        >
          <td>{{ i+1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ inputData.tryout_level }}</td>
          <td>{{ item.tryout_subject }}</td>
          <td>
            <b-button
              size="sm"
              class="mr-3 action-button"
              variant="outline-secondary"
              @click="viewPackage(item.id)"
            >
              <fa-icon icon="search" class="my-icon" />&nbsp;Check Package
            </b-button>
            <b-button
              size="sm"
              class="mr-3 action-button"
              variant="outline-danger"
              @click="removeQuestion(item.id, item.level)"
            >
              <fa-icon icon="trash-alt" class="my-icon" />&nbsp;Remove
            </b-button>
          </td>
        </tr>
      </draggable>
    </table>
    <br />
    <b-button
      variant="info"
      size="sm"
      v-if="saveOrderButton"
      @click="saveOrder()"
    >
      Simpan Urutan
    </b-button>
    <b-modal
      v-model="pilihModal"
      id="pilihModal"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <modal-cross @close="pilihModal = false"></modal-cross>
      <div class="p-4">
        <h3 class="txt-center color2">Pilih Paket Soal</h3>
        <br />
        <!-- <label>Cari Judul Soal</label>
        <br />
        <b-form-input placeholder="Title..." v-model="uploadData.title"></b-form-input> -->
        <!-- Main table element -->
        <div class="space-between align-center">
          <div class="align-center flex-1">
            <div class="filter-icon">
              <img src="/img/filter.svg" alt />
            </div>

            <div class="search-wrappper">
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  placeholder="Search here..."
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
        <br />
        <br />
        <b-table
          show-empty
          stacked="md"
          :busy="isBusy2"
          :items="items2"
          :fields="fields2"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
          selectable
          :select-mode="selectMode"
          selected-variant="success"
          @row-selected="onRowSelected"
          responsive="sm"
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-button
              size="sm"
              class="mr-3 action-button"
              variant="outline-secondary"
              @click="viewPackage(row.item.id)"
            >
              <fa-icon icon="search" class="my-icon" />&nbsp;Check Package
            </b-button>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
        <div class="justify-end">
          <my-button
            type="blue"
            @click="addPackage"
          >
            SUBMIT SOAL
          </my-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  data() {
    return {
      showLoading: false,
      levelOption: [],
      inputData: {
        name: "",
        description: "",
        tryout_level_id: null,
        tryout_level: "",
        price: 0,
        id: null,
        thumbnail_src: "/img/category-icons/thumbnail-default.svg",
        thumbnail: null,
        removeOld: 0
      },
      items: [],
      fields: [
        { key: "no", label: "No" },
        {
          key: "name",
          label: "Paket",
          sortable: false
        },
        {
          key: "tryout_level",
          label: "Jenjang",
          sortable: false,
          class: "text-center"
        },
        {
          key: "tryout_subject",
          label: "Mapel",
          sortable: false,
          class: "text-center"
        },
        { key: "actions", label: "Action" }
      ],
      saveOrderButton: false,
      pilihModal: false,
      isBusy2: false,
      fields2: [
        { key: "no", label: "No" },
        {
          key: "name",
          label: "Paket Soal",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "tryout_level",
          label: "Jenjang",
          sortable: true,
          class: "text-center"
        },
        {
          key: "tryout_subject",
          label: "Mapel",
          sortable: true,
          class: "text-center"
        },
        { key: "actions", label: "Action" }
      ],
      items2: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      selectMode: "multi",
      selected: []
    };
  },

  mounted() {
    this.getDetail();
  },

  methods: {
    ...mapActions({
      getTryoutDetail: types.GET_TRYOUT_DETAIL,
      updateTryout: types.UPDATE_TRYOUT,
      getTryoutPackageList: types.GET_TRYOUT_PACKAGE_LIST,
      setTryoutPackage: types.SET_TRYOUT_PACKAGE,
      removeTryoutPackage: types.REMOVE_TRYOUT_PACKAGE,
      updateTryoutPackageOrder: types.UPDATE_TRYOUT_PACKAGE_ORDER
    }),
    saveOrder() {
      this.$bvModal
        .msgBoxConfirm("Save new package order?")
        .then(value => {
          if (value) {
            var orderAllIds = [];
            for (var i = 0; i < this.items.length; i++) {
              orderAllIds.push(this.items[i].id);
            }

            this.updateTryoutPackageOrder({
              id: this.$route.params.id,
              order: orderAllIds.join(',')
            })
            .then((response) => {
              this.showMessageBox('Berhasil mengubah urutan soal', 'Success', 'success');
            })
            .catch((error) => {
              this.showMessageBox(error, 'Gagal mengubah urutan soal', 'success');
            });
          }
        })
        .catch(err => {
          this.showMessageBox(err, "Failed", "success");
        });
    },
    removeQuestion(id) {
      if (confirm("Do you really want to delete?")) {
        var payload = {
          id: this.$route.params.id,
          package_id: id
        };
        this.removeTryoutPackage(payload)
          .then(response => {
            // eslint-disable-next-line
            var res = response;
            this.pilihModal = false;
            this.showMessageBox("Paket dihapus", "Succees", "success");
            this.getDetail();
          })
          .catch(error => {
            this.pilihModal = false;
            this.showMessageBox(error, "Failed", "success");
          });
      }
    },
    viewPackage(id) {
      let routeData = this.$router.resolve({name: 'tryout-package-edit', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
    addPackage() {
      var packages = [];
      for (var i = 0; i < this.selected.length; i++) {
        var status = this.items.length > 0 ? this.items.some(j => j.id === this.selected[i].id) : 0;
        if(!status) {
          packages.push(this.selected[i]);
        }
      }
      if(packages.length > 0) {
        this.setTryoutPackage({tryout_id: this.$route.params.id, packages: packages})
        .then(response => {
          // eslint-disable-next-line
          var res = response;
          this.pilihModal = false;
          this.getDetail();
          this.showMessageBox("Berhasil tambah paket", "Succees", "success");
          // this.saveOrder();
        })
        .catch(error => {
          this.pilihModal = false;
          this.showMessageBox(error, "Failed", "success");
        });
      } else {
        this.showMessageBox("Soal sudah terdaftar", "Failed", "success");
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    tryoutPackageList() {
      var tryout_level_id = this.inputData.tryout_level_id;
      var tryout_subject_id = this.inputData.tryout_subject_id;
      var payload = {
        params: {
          tryout_level: tryout_level_id
        }
      }
      
      this.isBusy2 = true;
    
      this.getTryoutPackageList(payload)
        .then(response => {
          var res = response;
          this.items2 = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              no: i + 1,
              id: res[i].id,
              name: res[i].name,
              tryout_level: res[i].tryout_level,
              tryout_level_id: res[i].tryout_level_id,
              tryout_subject: res[i].tryout_subject,
              tryout_subject_id: res[i].tryout_subject_id,
            };
            this.items2.push(dataItem);
          }
          this.totalRows = res.length;
          this.isBusy2 = false;
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    pilihSoalBaru() {
      this.pilihModal = true;
      this.tryoutPackageList();
    },
    log() {
      this.saveOrderButton = true;
    },
    getDetail() {
      this.getTryoutDetail(this.$route.params.id)
        .then(response => {
          var res = response;
          this.inputData.name = res.name;
          this.inputData.description = res.description;
          this.inputData.id = res.id;
          this.inputData.tryout_level_id = res.tryout_level_id;
          this.inputData.tryout_level = res.tryout_level;
          this.inputData.price = res.price;
          this.inputData.thumbnail_src = res.thumbnail ? res.thumbnail : '/img/category-icons/thumbnail-default.svg';
          this.inputData.thumbnail = null;
          this.levelOption = [{value: res.tryout_level_id, text: res.tryout_level}];
          this.items = res.package;
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    save() {
      if(this.inputData.tryout_level_id && this.inputData.name) {
        this.showLoading = true;
        let newFormData = new FormData();
        newFormData.append("id", this.$route.params.id);
        newFormData.append("name", this.inputData.name);
        newFormData.append("description", this.inputData.description);
        newFormData.append("tryout_level_id", this.inputData.tryout_level_id);
        newFormData.append("thumbnail", this.inputData.thumbnail);
        newFormData.append("price", this.inputData.price ? this.inputData.price : 0);
        if(this.inputData.removeOld) {
          newFormData.append("removeOld", this.inputData.removeOld);
        }
        this.updateTryout(newFormData)
          .then(response => {
            this.showLoading = false;
          })
          .catch(error => {
            this.showLoading = false;
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.showMessageBox("Lengkapi data", "Failed", "success");
      }
    },
    changeThumbnail() {
      var fileData = event.target.files[0];
      this.inputData.thumbnail = fileData;
      this.inputData.thumbnail_src = URL.createObjectURL(fileData);
    },
    removeImage() {
      this.inputData.thumbnail_src = "/img/category-icons/thumbnail-default.svg";
      this.inputData.thumbnail = null;
      this.inputData.removeOld = 1;
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
        })
        .catch(err => {
        });
    }
  },
};
</script>

<style>
.cat-icon-container {
  width: 108px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9f8ff;
}
.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}
</style>
